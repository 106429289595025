import shaJs from 'sha.js'
import qs from 'qs';
import randomBytes from 'randombytes'
import merge from 'lodash.merge';
import EventEmitter from 'events';
import "regenerator-runtime";

// if promise hasn't been polyfilled polyfill it just for this file
var _Promise = typeof Promise === 'undefined' ? require('es6-promise').Promise : Promise;

const SESSION_API_URL = 'https://sessions.cimpress.io/v1/sessions';
const PROFILE_API_URL = 'https://profile.cimpress.io/v1/profile';
const ACCOUNT_ID_CLAIM = 'https://claims.cimpress.io/account';
const TEST_USER_CLAIM = 'https://claims.cimpress.io/is_test_user';
const PROFILE_EXPIRY_TIME = 3600 * 1000;
const ID_TOKEN_EXPIRY_TIME = 36000 * 1000; // This is the default expiry for Id Token.
const ID_TOKEN_EXPIRY_TIME_OFFSET = 50400 * 1000; // This is the offset from the AccessToken.
const DEFAULT_OPTIONS = {
  redirectRoute: '',
  domain: 'cimpress.auth0.com',
  audience: 'https://api.cimpress.io/',
  scope: 'offline_access',

  // number of seconds offset - value of 30 means token will be considered expired 30 seconds before it actually expires with the provider
  sessionExpirationOffset: 30,
  // number of seconds offset - value of 30 means token will be considered expired 30 seconds before it actually expires with the provider
  tokenExpirationOffset: 30,
  // Check to see if the token is expired when the window comes into focus. This is because the expiration timer is sometimes unreliable.
  checkExpirationOnFocus: true,
  // Check to see if the calling client supports Session or not.
  sessionEnabled: true,
  // Check if ID token is required. If yes, then the token expiry would be that of Id Token (10 hours) rather than the expiry of access token.
  requireIDToken: false
};

// https://auth0.com/docs/flows/authorization-code-flow-with-proof-key-for-code-exchange-pkce
export default class AuthorizationCodeGrantPKCE {

  constructor (options) {
    merge(this, DEFAULT_OPTIONS, options);

    this.redirectUri = window.location.origin + this.redirectRoute;
    this.scope = this.requireIDToken ? this.scope + ' openid' : this.scope;
    this.events = new EventEmitter();

    // listen for changes to localStorage that don't originate from this current window/tab
    window.addEventListener('storage', this.listenToStorage);

    // The sessionExpired event can be unreliable (for example when your computer goes to sleep)
    // So we are double checking that you are logged in when the browser tab gains focus.
    window.addEventListener('visibilityChange', this.handleFocusChange);
  }

  // Utility which enables a developer to subscribe to the events 'listenToStorage' & 'handleFocusChange'
  on = (eventType, ...args) => {
    this.events.on(eventType, ...args);
  };

  // Utility which enables a developer to unsubscribe to the events 'listenToStorage' & 'handleFocusChange'
  removeListener = (eventType, ...args) => {
    this.events.removeListener(eventType, ...args);
  };

  // It is possible to create an infinite loop between 2 tabs/windows if localStorage is modified
  // within this listener - so don't do it :) 
  listenToStorage = (e) => {
    switch (e.key) {
      case 'sessionExpiresAt':
        // check to see if it's being removed or not
        if (e.newValue) {
          try {
            const expiresAt = JSON.parse(e.newValue);
            this.setSessionExpirationTimer(expiresAt);
          } catch (e) {
            this.clearLocalParams();
            console.error(e);
            throw new SyntaxError(`An unexpected error occurred due to invalid sessionExpiresAt value: ${e.message}`, e.stack)
          }
        }
        break;
      case 'tokenExpiresAt':
        // check to see if it's being removed or not
        if (e.newValue) {
          try {
            const expiresAt = JSON.parse(e.newValue);
            this.setTokenExpirationTimer(expiresAt);
          } catch (e) {
            this.clearLocalParams();
            console.error(e);
            throw new SyntaxError(`An unexpected error occurred due to invalid tokenExpiresAt value: ${e.message}`, e.stack)
          }
        }
        break;
      default:
    }
  };

  // Check  session status by calling API
  isLoggedIn = async () => {
    const sessionId = localStorage.getItem('sessionId')
    if (sessionId) {
      const sessionData = await this.checkSession(sessionId)
      return ['ACCESS_TOKEN_EXPIRED', 'ACTIVE'].includes(sessionData.status)
    } else {
      return _Promise.resolve(false);
    }
  };

  // Check for the session's expiration time whenever the the web browser's tab regains focus
  handleFocusChange = async () => {
    if (document.visibilityState === 'visible' && this.checkExpirationOnFocus) {
      // Always check for Session Expiration
      const expiration = localStorage.getItem('sessionExpiresAt');
      if (expiration && expiration <= new Date().getTime() - (this.sessionExpirationOffset * 1000)) {
        this.events.emit('sessionExpired', expiration);
      }

      // Refresh the access token only if sessionEnabled is false
      if (!this.sessionEnabled) {
        const tokenExpiration = localStorage.getItem('tokenExpiresAt');
        if (tokenExpiration && tokenExpiration <= new Date().getTime() - (this.tokenExpirationOffset * 1000)) {
          await this.refreshAccessTokenWrapper(true);
        }
      }
    }
  }

  // Generate a Code Verifier of 32 random bytes
  generateCodeVerifier = () => {
    return this.urlEncode(
      randomBytes(32).toString("base64")
    )
  }

  // Encrypt the 32 random bytes with sha.js
  createCodeChallenge = (codeVerifier) => {
    return this.urlEncode(
      shaJs("sha256")
        .update(codeVerifier)
        .digest("base64")
    )
  }

  // Encode the string so that its properly consumed by the auth0 server
  urlEncode = (str) => {
    return str
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=/g, "")
  }

  // Generate the code verifier and code challenge and redirect the user to a centralized auth0 page
  login = ({ nextUri = window.location.href, authorizeParams } = {}) => {
    const state = btoa(nextUri);
    const verifier = this.generateCodeVerifier()
    const challenge = this.createCodeChallenge(verifier)
    localStorage.setItem('codeVerifier', verifier);

    let queryStringParams = {
      state,
      response_type: 'code',
      client_id: this.clientID,
      audience: this.audience,
      code_challenge: challenge,
      code_challenge_method: 'S256',
      scope: this.scope,
      redirect_uri: this.redirectUri,
      ...authorizeParams
    }

    window.location = `https://${this.domain}/authorize?${qs.stringify(queryStringParams)}`;
    return _Promise.resolve();
  }

  // If the redirect was from auth0 then a fresh session is created, else the existing session is checked for its validity and the necessary steps are performed
  handleAuthentication = async ({ performRedirect = true } = {}) => {
    const sessionId = localStorage.getItem('sessionId');

    if (this.wasAuth0Redirect()) {
      return this.handleRedirect({ performRedirect });
    } else if (sessionId) {
      // check the status of session else proceed with the login flow
      const sessionData = await this.checkSession(sessionId);
      if (this.sessionEnabled && ['ACCESS_TOKEN_PENDING', 'ACTIVE'].includes(sessionData.status)) {
        // set the latest expiry for session
        localStorage.setItem('sessionExpiresAt', new Date(sessionData.expiresAt).getTime());
        this.setSessionExpirationTimer();
        return _Promise.resolve(true);
      }
      else if (!this.sessionEnabled) {
        const accessTokenExpiration = new Date(sessionData.accessTokenExpiresAt).getTime();

        // Handling the ID token expiry
        const tokenExpiresIn = accessTokenExpiration - (this.requireIDToken ? ID_TOKEN_EXPIRY_TIME_OFFSET : 0) - (this.tokenExpirationOffset * 1000);
        // Check if token has expired and session is still active
        // Only if it has expired, check if there is a need for refreshing token
        // Else return false and re-initiate login flow
        if ((sessionData.status === 'ACTIVE' && accessTokenExpiration && tokenExpiresIn <= new Date().getTime())
          || (['ACCESS_TOKEN_EXPIRED', 'ACCESS_TOKEN_MISSING'].includes(sessionData.status))) {
          // set the latest expiry for session
          localStorage.setItem('sessionExpiresAt', new Date(sessionData.expiresAt).getTime());
          this.setSessionExpirationTimer();

          return await this.refreshAccessTokenWrapper(false);
        } else if (sessionData.status === 'ACTIVE' && accessTokenExpiration) {
          // set the latest expiry for session & token
          localStorage.setItem('sessionExpiresAt', new Date(sessionData.expiresAt).getTime());
          localStorage.setItem('tokenExpiresAt', new Date(accessTokenExpiration - (this.requireIDToken ? ID_TOKEN_EXPIRY_TIME_OFFSET : 0)).getTime());
          this.setSessionExpirationTimer();
          this.setTokenExpirationTimer();

          return _Promise.resolve(true);
        }
      }
    }
    this.clearLocalParams();
    return _Promise.resolve(false);
  }

  // This method is combination of handleAuthentication() method & login() method. Its the starting point of the authentication process
  ensureAuthentication = (options = {}) => {
    const { forceLogin } = options;
    if (forceLogin) {
      return this.login(options);
    }

    return this.handleAuthentication().then(authenticated => {
      if (authenticated) {
        return _Promise.resolve(true);
      }

      return this.login(options);
    });
  };

  // Checks whether the call is sent back by auth0 server with the code and status as the url parameters.
  wasAuth0Redirect = () => {
    const parsedUrl = this.getFragments();
    return parsedUrl['code'] && parsedUrl['state'];
  }

  // Extracts code and state params from the url and returns a dictionary.
  getFragments = () => {
    if (!window.location.search) { return {}; }

    return window.location.search
      .substring(1)
      .split('&')
      .reduce(function (prev, cur) {
        var kv = cur.split('=');
        prev[kv[0]] = kv[1];
        return prev;
      }, {});
  }

  // If it was a redirect from the auth0 server, then a fresh session is created and the profile information of the user is extracted.
  handleRedirect = async ({ performRedirect }) => {
    try {
      const parsedUrl = this.getFragments();
      const authorizationCode = parsedUrl['code'];
      const state = parsedUrl['state'];
      const nextUri = atob(decodeURIComponent(state));

      if (this.sessionEnabled) { // Proceed with the session only flow
        const sessionData = await this.createSession(authorizationCode);

        if (sessionData.sessionId) {
          localStorage.removeItem('codeVerifier');
          // Store session related information
          localStorage.setItem('sessionId', sessionData.sessionId);
          localStorage.setItem('sessionExpiresAt', new Date(sessionData.expiresAt).getTime());

          // Call to get Profile information of user
          await this.getProfile(sessionData.sessionId);
          if (performRedirect) {
            window.location = nextUri || '/';
          }
          return true;
        } else {
          return false;
        }
      } else { // Proceed with the session + token flow
        const tokenData = await this.getAccessToken(authorizationCode);

        if (tokenData.refresh_token) {
          // Store session related information
          localStorage.setItem('refreshToken', tokenData.refresh_token);
          this.requireIDToken && localStorage.setItem('token', tokenData.id_token); // storing id token
          const tokenExpiresIn = new Date().getTime() + (this.requireIDToken ? ID_TOKEN_EXPIRY_TIME : (tokenData.expires_in * 1000));
          localStorage.setItem('tokenExpiresAt', tokenExpiresIn);
          localStorage.setItem('accessToken', tokenData.access_token);

          const sessionData = await this.createSession(undefined, tokenData.access_token);

          if (sessionData.sessionId) {
            localStorage.removeItem('codeVerifier');
            // Store session related information
            localStorage.setItem('sessionId', sessionData.sessionId);
            localStorage.setItem('sessionExpiresAt', new Date(sessionData.expiresAt).getTime());

            // Call to get Profile information of user
            await this.getProfile(sessionData.sessionId);
            if (performRedirect) {
              window.location = nextUri || '/';
            }
          } else {
            return false;
          }

          return true;
        } else {
          return false;
        }
      }
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  // Returns a session for a user in exchange of a Authorization Code and Code Verifier
  createSession = async (authorizationCode, accessToken) => {
    if (this.sessionEnabled) {
      return this.fetchWithNoRetry(SESSION_API_URL, {
        method: 'POST',
        body: JSON.stringify({
          'origin': window.location.origin,
          'clientId': this.clientID,
          'authorizationCode': authorizationCode,
          'codeVerifier': localStorage.getItem('codeVerifier'),
          'redirectUri': this.redirectUri,
        }),
        headers: new Headers({
          Accept: 'application/json',
          'Content-Type': 'application/json'
        })
      });
    } else {
      return this.fetchWithRetry(SESSION_API_URL, {
        method: 'POST',
        body: JSON.stringify({
          'origin': window.location.origin,
          'accessToken': accessToken,
          'clientId': this.clientID
        }),
        headers: new Headers({
          Accept: 'application/json',
          'Content-Type': 'application/json'
        })
      });
    }
  }

  patchSessionForAccessToken = async (accessToken) => {
    return this.fetchWithRetry(`${SESSION_API_URL}/${localStorage.getItem('sessionId')}/tokens`, {
      method: 'PUT',
      body: JSON.stringify({
        'accessToken': accessToken
      }),
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json'
      })
    });
  }

  getAccessToken = async (authorizationCode) => {
    return this.fetchWithNoRetry(`https://${this.domain}/oauth/token`, {
      method: 'POST',
      body: JSON.stringify({
        grant_type: 'authorization_code',
        client_id: this.clientID,
        code_verifier: localStorage.getItem('codeVerifier'),
        code: authorizationCode,
        redirect_uri: this.redirectUri
      }),
      headers: new Headers({
        'Content-Type': 'application/json'
      })
    });
  }

  refreshAccessToken = async () => {
    const params = {
      grant_type: 'refresh_token',
      client_id: this.clientID,
      refresh_token: localStorage.getItem('refreshToken')
    };
    const searchParams = Object.keys(params).map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return this.fetchWithNoRetry(`https://${this.domain}/oauth/token`, {
      method: 'POST',
      body: searchParams,
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      })
    });
  }

  // Returns the status of the session in exchange of a session ID
  checkSession = async (sessionId) => {
    if (sessionId) {
      return this.fetchWithNoRetry(`${SESSION_API_URL}/${sessionId}/status?cacheBurst=${Date.now()}`)
    } else {
      return _Promise.resolve({});
    }
  }

  // Returns the profile information of a user in exchange of a session ID
  getProfile = async (sessionId) => {
    const profileExpiresAt = localStorage.getItem('profileExpiresAt');
    let profileResponse = {}

    if (!profileExpiresAt || profileExpiresAt <= new Date().getTime()) {
      try {
        const result = await fetch(`${PROFILE_API_URL}/me`, {
          method: 'GET',
          headers: new Headers({ 'x-session-id': sessionId })
        })
        if (!result.ok) throw new Error()
        profileResponse = await result.json()
      }
      catch (err) {
        if (!this.sessionEnabled) {
          const result = await fetch(`${PROFILE_API_URL}/me`, {
            method: 'GET',
            headers: new Headers({ Authorization: 'Bearer ' + localStorage.getItem('accessToken') })
          })
          if (result.ok) { profileResponse = await result.json() }
        } else {
          console.error('Error fetching profile - ', err)
        }
      }
      finally {
        if (profileResponse.canonicalId) {
          const profileData = {
            canonicalId: profileResponse.canonicalId,
            email: profileResponse.email,
            given_name: profileResponse.firstName,
            family_name: profileResponse.lastName || "",
            [ACCOUNT_ID_CLAIM]: profileResponse.accountId,
            name: profileResponse.firstName + (profileResponse.lastName === undefined ? "" : " " + profileResponse.lastName),
            picture: profileResponse.pictureURL,
            [TEST_USER_CLAIM]: profileResponse.isTestUser
          }
          localStorage.setItem('profile', JSON.stringify(profileData));
          localStorage.setItem('profileExpiresAt', new Date().getTime() + PROFILE_EXPIRY_TIME);
          return profileData;
        }
        return {};
      }
    } else {
      return JSON.parse(localStorage.getItem('profile'));
    }
  }

  // Clears the timer instance 'expiresSessionTimeout' associated with the class 
  clearSessionTimeout = () => this.expiresSessionTimeout = window.clearTimeout(this.expiresSessionTimeout);

  // Clears the timer instance 'expiresTokenTimeout' associated with the class 
  clearTokenTimeout = () => this.expiresTokenTimeout = window.clearTimeout(this.expiresTokenTimeout);

  setSessionExpirationTimer = () => {
    const sessionExpiresAt = localStorage.getItem('sessionExpiresAt');

    try {
      this.clearSessionTimeout();
      const timeToWait = sessionExpiresAt - new Date().getTime() - (1000 * this.sessionExpirationOffset);
      this.expiresSessionTimeout = window.setTimeout(
        () => this.events.emit('sessionExpired'), Math.max(Math.min(Math.pow(2, 31) - 1, timeToWait), 0) // Checking max value for setTimeout
      );
    } catch (err) {
      console.error(err);
    }
  };

  setTokenExpirationTimer = () => {
    const tokenExpiresAt = localStorage.getItem('tokenExpiresAt');

    try {
      this.clearTokenTimeout();
      let timeToWait = tokenExpiresAt - new Date().getTime() - (1000 * this.tokenExpirationOffset);
      this.expiresTokenTimeout = window.setTimeout(
        async () => await this.refreshAccessTokenWrapper(true), Math.max(timeToWait, 0)
      );
    } catch (err) {
      console.error(err);
    }
  };

  refreshAccessTokenWrapper = async (isTokenRefreshedEventEmissionRequired = false) => {
    const tokenData = await this.refreshAccessToken();

    if (tokenData.refresh_token) {
      // Store token related information
      localStorage.setItem('refreshToken', tokenData.refresh_token);
      this.requireIDToken && localStorage.setItem('token', tokenData.id_token); // storing id token
      const tokenExpiresIn = new Date().getTime() + (this.requireIDToken ? ID_TOKEN_EXPIRY_TIME : (tokenData.expires_in * 1000));
      localStorage.setItem('tokenExpiresAt', tokenExpiresIn);
      localStorage.setItem('accessToken', tokenData.access_token);

      // reset token expiration timer
      this.setTokenExpirationTimer();
      // Call to update access token on Session.
      await this.patchSessionForAccessToken(tokenData.access_token);
      if (isTokenRefreshedEventEmissionRequired) {
        this.events.emit('tokenRefreshed');
      }
      return true;
    }
    this.clearLocalParams();
    return false;
  }

  // Closes the session in exchange of a session ID and clears the local storage
  logout = async (nextUri, logoutOfFederated) => {
    // Auth0 logout code
    let redirectUrl = nextUri ? window.location.origin + nextUri : window.location.origin;
    let url = `https://${this.domain}/v2/logout`
    url += `?client_id=${this.clientID}`
    url += `&returnTo=${redirectUrl}`
    if (logoutOfFederated) {
      url += '&federated'
    }
    const sessionId = localStorage.getItem('sessionId')
    try {
      if (sessionId) {
        await fetch(`${SESSION_API_URL}/${sessionId}/lifecycle/logout`, {
          method: 'POST',
          headers: new Headers({
            'x-session-id': sessionId
          })
        });
      }
    } catch (err) {
      console.error(err)
    } finally {
      this.clearLocalParams();
      window.location = url;
    }
  }

  // Clears the local storage
  clearLocalParams = () => {
    this.clearSessionTimeout();
    this.clearTokenTimeout();
    this.clearLocalStorage();
  };

  clearLocalStorage = () => {
    localStorage.removeItem('sessionId');
    localStorage.removeItem('sessionExpiresAt');
    localStorage.removeItem('profile');
    localStorage.removeItem('profileExpiresAt');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('tokenExpiresAt');
    localStorage.removeItem('token');
  }

  fetchWithRetry = async (url, options = {}, retries = 3) => {
    try {
      const retryCodes = [408, 429, 500, 502, 503, 504, 522, 524]

      const result = await fetch(url, options);
      if (result.ok) {
        return result.json();
      }

      if (retries > 0 && retryCodes.includes(result.status)) {
        const data = await this.fetchWithRetry(url, options, retries - 1);
        return data;
      } else {
        throw new Error(result);
      }
    } catch (err) {
      console.error(JSON.stringify(err));
    }
  }

  fetchWithNoRetry = async (url, options = {}) => {
    try {
      const result = await fetch(url, options);
      if (result.ok) {
        return result.json();
      }
      throw new Error(result);
    } catch (err) {
      console.error(JSON.stringify(err));
      return {}
    }
  }
}
