const BRANDING_SERVICE_API_URL = 'https://api.branding.cimpress.io/v0/brands';

export const fetchBrandingInfo = (accessToken, sessionId) => {
  let fetchUrl = BRANDING_SERVICE_API_URL;
  let fetchMethod = 'get';

  if (!accessToken) {
    //reset variables to proxy values
    fetchUrl = `https://sessions.cimpress.io/v1/sessions/proxy?proxyUrl=${fetchUrl}&proxyUrlMethod=${fetchMethod}`;
    fetchMethod = 'post';
  }

  return fetch(fetchUrl, {
    method: fetchMethod,
    mode: 'cors',
    headers: new Headers({
      'Content-Type': 'application/json',
      ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : { 'x-session-id': sessionId }),
    }),
    ...(!accessToken && { body: JSON.stringify({}) }),
  })
    .then(response => {
      if (response.status === 200) {
        return response.json().then(results => {
          if (results.total < 1) {
            // we must get at least 1 brand
            return null;
          }
          return results._embedded.item[0];
        });
      } else {
        return null;
      }
    })
    .catch(error => {
      console.error(error);
      return null;
    });
};
